import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  CHECK_LIST,
  LINK,
  ELEMENT_TRANSFORMERS,
  ElementTransformer,
  MULTILINE_ELEMENT_TRANSFORMERS,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
  TextMatchTransformer,
  Transformer,
  MultilineElementTransformer,
} from '@lexical/markdown';
import {
  $createHorizontalRuleNode,
  $isHorizontalRuleNode,
  HorizontalRuleNode,
} from '@lexical/react/LexicalHorizontalRuleNode';
import {
  $createTableCellNode,
  $createTableNode,
  $createTableRowNode,
  $isTableCellNode,
  $isTableNode,
  $isTableRowNode,
  TableCellHeaderStates,
  TableCellNode,
  TableNode,
  TableRowNode,
} from '@lexical/table';
import {
  $isLineBreakNode,
  $isParagraphNode,
  $isTextNode,
  LexicalNode,
  TEXT_TYPE_TO_FORMAT,
  TextFormatType,
  $createParagraphNode,
  $createLineBreakNode,
  $createTextNode,
} from 'lexical';
import {
  $createImageNode,
  $isImageNode,
  ImageNode,
} from '../../nodes/ImageNode';
import emojiList from '../../utils/emoji-list';

import {
  $createExtendedTextNode,
  $isExtendedTextNode,
  ExtendedTextNode,
  transformerRegExp,
} from '../../nodes/ExtendableTextNode';
import {
  $createAdmonitionNode,
  $isAdmonitionNode,
  AdmonitionNode,
  AdmonitionType,
} from '../../nodes/AdmonitionNode';
import { TYPE_ICON_MAP } from '../AdmonitionPlugin';
import { $createIconNode } from '../../nodes/AdmonitionNode/AdmonitionIconNode';
import {
  applyFormatsFromStyleString,
  getCssPropertyFromFormat,
} from '../../utils/applyFormatsToMarkdownTransform';
import {
  ADMONITION_END_REGEX,
  ADMONITION_START_REGEX,
  DEFAULT_TABLE_ROW_HEIGHT,
  EMOJI_REGEX,
  EXTENDED_TEXT_REGEX,
  HR_REGEX,
  IMAGE_IMPORT_REGEX,
  IMAGE_REPLACE_REG_EXP,
  TABLE_REG_EXP,
} from './constants';

export const ADMONITION: MultilineElementTransformer = {
  dependencies: [AdmonitionNode],
  export: (node: LexicalNode) => {
    if (!$isAdmonitionNode(node)) {
      return null;
    }
    const admonitionType = node.__admonitionType;
    const content = node
      .getChildren()
      .slice(1) // Skip the first child because it's the title
      .map(child => {
        if ($isParagraphNode(child)) {
          return child
            .getTextContent()
            .split('\n')
            .map(line => `    ${line}`) // Add indentation of 4 spaces to each line
            .join('\n');
        }
        return '';
      })
      .join('\n');
    const title = node.getChildren()[0].getTextContent();
    return `!!! ${admonitionType} "${title}"\n${content}\n!!!`;
  },
  regExpStart: ADMONITION_START_REGEX,
  regExpEnd: {
    optional: true,
    regExp: ADMONITION_END_REGEX,
  },
  replace: (
    rootNode,
    children,
    startMatch,
    endMatch,
    linesInBetween,
    isImport
  ) => {
    const [, admonitionType, title] = startMatch;
    const admonitionNode = $createAdmonitionNode(
      admonitionType as AdmonitionType,
      title
    );
    admonitionNode.setTitle(title);

    const titleParagraph = $createParagraphNode();
    const IconComponent = TYPE_ICON_MAP[admonitionType];
    const titleNode = $createTextNode(title);
    const iconNode = $createIconNode(IconComponent, admonitionType);
    titleParagraph.append(iconNode);
    titleParagraph.append($createTextNode(' ')); // Add space before the title
    titleParagraph.append(titleNode);
    admonitionNode.append(titleParagraph);

    if (children) {
      children.forEach(child => admonitionNode.append(child));
    } else if (linesInBetween) {
      const trimmedLines = linesInBetween
        .join('\n')
        .trim()
        .split('\n');
      const paragraphs = trimmedLines
        .map(line => (line.startsWith('    ') ? line.slice(4) : line))
        .map(paragraphText => {
          const paragraphNode = $createParagraphNode();
          paragraphText.split('\n').forEach((line, index, array) => {
            paragraphNode.append($createTextNode(line));
            if (index < array.length - 1) {
              paragraphNode.append($createLineBreakNode());
            }
          });
          return paragraphNode;
        });

      paragraphs.forEach(paragraph => admonitionNode.append(paragraph));
    }
    rootNode.append(admonitionNode);

    if (isImport && endMatch) {
      // Continue transforming all markdown after the end of the admonition
      return true;
    }
  },
  type: 'multiline-element',
};

export const EXTENDED_TEXT: TextMatchTransformer = {
  dependencies: [ExtendedTextNode],
  export: (node: LexicalNode, _exportChildren, _exportFormat) => {
    if ($isLineBreakNode(node)) {
      return '\n';
    }
    if ($isExtendedTextNode(node)) {
      let style = node.getStyle();
      for (const key in TEXT_TYPE_TO_FORMAT) {
        if (node.hasFormat(key as TextFormatType)) {
          const cssProperty = getCssPropertyFromFormat(key);
          if (style.includes(cssProperty)) {
            style = style.replace(cssProperty, '');
          }
          style += cssProperty;
        }
      }
      const textContent = node.getTextContent();

      return style
        ? `[style=${style.trim()}]${textContent}[/style]`
        : textContent;
    }
    return '';
  },
  importRegExp: EXTENDED_TEXT_REGEX,
  regExp: EXTENDED_TEXT_REGEX,
  replace: (textNode, _match) => {
    const text = _match.input;
    const matches = [...text.matchAll(transformerRegExp)];
    const nodes = [];
    let lastIndex = 0;

    matches.forEach(match => {
      const matchStart = match.index;
      const matchEnd = matchStart + match[0].length;
      if (lastIndex < matchStart) {
        nodes.push($createTextNode(text.slice(lastIndex, matchStart)));
      }
      const style = match[1];
      const content = match[2];
      const extendedTextNode = $createExtendedTextNode(content);
      if (style) {
        extendedTextNode.setStyle(style);
        applyFormatsFromStyleString(extendedTextNode, style);
      }
      nodes.push(extendedTextNode);
      lastIndex = matchEnd;
    });

    if (lastIndex < text.length) {
      nodes.push($createTextNode(text.slice(lastIndex)));
    }

    nodes.forEach(node => textNode.insertBefore(node));
    textNode?.remove();
  },
  trigger: '',
  type: 'text-match',
};

export const HR: ElementTransformer = {
  dependencies: [HorizontalRuleNode],
  export: (node: LexicalNode) => {
    return $isHorizontalRuleNode(node) ? '***' : null;
  },
  regExp: HR_REGEX,
  replace: (parentNode, _1, _2, isImport) => {
    const line = $createHorizontalRuleNode();
    if (isImport || parentNode.getNextSibling() != null) {
      parentNode.replace(line);
    } else {
      parentNode.insertBefore(line);
    }

    line.selectNext();
  },
  type: 'element',
};

export const IMAGE: TextMatchTransformer = {
  dependencies: [ImageNode],
  export: node => {
    if (!$isImageNode(node)) {
      return null;
    }

    return `![${node.getAltText()}](${node.getSrc()}){width: ${node.__maxWidth ||
      node.__width}}`;
  },
  importRegExp: IMAGE_IMPORT_REGEX,
  regExp: IMAGE_REPLACE_REG_EXP,
  replace: (textNode, match) => {
    const [, altText, src, width] = match;
    const imageWidth = width ? parseInt(width, 10) : 800;
    const imageNode = $createImageNode({
      altText,
      maxWidth: imageWidth,
      width: imageWidth,
      src,
    });
    textNode.replace(imageNode);
  },
  trigger: ')',
  type: 'text-match',
};

export const EMOJI: TextMatchTransformer = {
  dependencies: [],
  export: () => null,
  importRegExp: EMOJI_REGEX,
  regExp: EMOJI_REGEX,
  replace: (textNode, [, name]) => {
    const emoji = emojiList.find(e => e.aliases.includes(name))?.emoji;
    if (emoji) {
      textNode.replace($createTextNode(emoji));
    }
  },
  trigger: ':',
  type: 'text-match',
};

export const TABLE: ElementTransformer = {
  dependencies: [TableNode, TableRowNode, TableCellNode],
  export: (node: LexicalNode) => {
    if (!$isTableNode(node)) return null;

    const colWidths = node.getColWidths();
    const output: string[] = [];

    for (const row of node.getChildren()) {
      const rowOutput = [];
      if (!$isTableRowNode(row)) {
        continue;
      }

      let isHeaderRow = false;
      for (const cell of row.getChildren()) {
        if ($isTableCellNode(cell)) {
          rowOutput.push(
            $convertToMarkdownString(MARKDOWN_TRANSFORMERS, cell)
              .replace(/\n/g, '\\n')
              .trim()
          );
          if (cell.__headerState === TableCellHeaderStates.ROW) {
            isHeaderRow = true;
          }
        }
      }

      output.push(`| ${rowOutput.join(' | ')} |`);
      if (isHeaderRow) {
        output.push(`| ${rowOutput.map(_ => '---').join(' | ')} |`);
      }
    }
    output.push(`[colWidths=${colWidths.join(', ')}]`);
    return output.join('\n');
  },
  regExp: TABLE_REG_EXP,
  replace: (parentNode, _1, match) => {
    if (match[2]) {
      // Parse the colWidths from the captured string.
      const colWidthsStr = match[2];
      const colWidths = colWidthsStr
        .split(',')
        .map(s => parseInt(s.trim(), 10));
      // The table should have been created already.
      const previousSibling = parentNode.getPreviousSibling();
      if ($isTableNode(previousSibling)) {
        previousSibling.setColWidths(colWidths);
      }
      // Remove the colWidths paragraph.
      parentNode.remove();
      return;
    }

    const matchCells = mapToTableCells(match[0]);
    if (!matchCells) return;

    const rows = [matchCells];
    let sibling = parentNode.getPreviousSibling();
    let maxCells = matchCells.length;

    while (sibling) {
      if (!$isParagraphNode(sibling) || sibling.getChildrenSize() !== 1) {
        break;
      }

      const firstChild = sibling.getFirstChild();
      if (!$isTextNode(firstChild)) {
        break;
      }

      const cells = mapToTableCells(firstChild.getTextContent());
      if (!cells) {
        break;
      }

      maxCells = Math.max(maxCells, cells.length);
      rows.unshift(cells);
      const previousSibling = sibling.getPreviousSibling();
      sibling.remove();
      sibling = previousSibling;
    }

    const table = $createTableNode();

    for (const cells of rows) {
      const tableRow = $createTableRowNode();
      tableRow.setHeight(DEFAULT_TABLE_ROW_HEIGHT);
      table.append(tableRow);

      for (let i = 0; i < maxCells; i++) {
        tableRow.append(i < cells.length ? cells[i] : $createTableCell(''));
      }
    }

    const previousSibling = parentNode.getPreviousSibling();
    if (
      $isTableNode(previousSibling) &&
      getTableColumnsSize(previousSibling) === maxCells
    ) {
      previousSibling.append(...table.getChildren());
      parentNode.remove();
    } else {
      parentNode.replace(table);
    }
  },
  type: 'element',
};

function getTableColumnsSize(table: TableNode) {
  const row = table.getFirstChild();
  return $isTableRowNode(row) ? row.getChildrenSize() : 0;
}

const $createTableCell = (textContent: string): TableCellNode => {
  textContent = textContent.replace(/\\n/g, '\n');
  const cell = $createTableCellNode(TableCellHeaderStates.NO_STATUS);
  if (textContent.trim() !== '') {
    $convertFromMarkdownString(textContent, MARKDOWN_TRANSFORMERS, cell);
  }
  return cell;
};

const mapToTableCells = (textContent: string): Array<TableCellNode> | null => {
  const match = textContent.match(TABLE_REG_EXP);
  if (!match || !match[1]) {
    return null;
  }
  return match[1].split('|').map(text => $createTableCell(text));
};

function isRowAllDashes(row) {
  const cells = row.getChildren().filter(cell => $isTableCellNode(cell));
  if (cells.length === 0) {
    return false;
  }
  return cells.every(cell => {
    const text = cell
      .getTextContent()
      .trim()
      .replace(/:/g, '');
    return /^-+$/.test(text);
  });
}

function removeDashedRowAndMarkHeader(table: TableNode) {
  const rows = table.getChildren().filter($isTableRowNode) as TableRowNode[];
  if (rows.length === 0) return;
  let headerRow: TableRowNode | null = null;
  const dividerIndex = rows.findIndex(isRowAllDashes);
  if (dividerIndex >= 0) {
    headerRow = rows[dividerIndex - 1];
    rows[dividerIndex].remove();
  } else {
    headerRow = rows[0];
  }
  if (headerRow && dividerIndex >= 0) {
    headerRow.getChildren().forEach(cell => {
      if ($isTableCellNode(cell)) {
        cell.setHeaderStyles(
          TableCellHeaderStates.ROW,
          TableCellHeaderStates.ROW
        );
      }
    });
  }
}

export function mergeConsecutiveTablesTransform(tableNode: TableNode) {
  // This transform will be called whenever a TableNode appears/changes
  // We use it to check if the next sibling is also a TableNode with the same number of columns.
  const nextSibling = tableNode.getNextSibling();
  if ($isTableNode(nextSibling)) {
    const columnsA = getTableColumnsSize(tableNode);
    const columnsB = getTableColumnsSize(nextSibling);
    // If they match, merge them
    if (columnsA === columnsB) {
      // Move all TableRowNodes from nextSibling -> tableNode
      const rowsToMove = nextSibling.getChildren();
      for (let row of rowsToMove) {
        tableNode.append(row);
      }
      // Remove the now-empty nextSibling
      nextSibling.remove();
    }
  }
  // Now that tableNode may have gained extra rows, remove dashed row & mark header if necessary
  removeDashedRowAndMarkHeader(tableNode);
}

// Note: Order of transformers matters. They are applied in the order they added with the lower index getting priority.
// E.g. LINK will have priority over EXTENDED_TEXT
export const MARKDOWN_TRANSFORMERS: Array<Transformer> = [
  IMAGE,
  LINK,
  EXTENDED_TEXT,
  ADMONITION,
  TABLE,
  HR,
  EMOJI,
  CHECK_LIST,
  ...ELEMENT_TRANSFORMERS,
  ...MULTILINE_ELEMENT_TRANSFORMERS,
  ...TEXT_FORMAT_TRANSFORMERS,
  ...TEXT_MATCH_TRANSFORMERS,
];
